<template>
  <div class="edit">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" ref="content">
      <div class="left" ref="left">
        <div class="left-title">测评中心</div>
        <ul class="left-form" ref="leftForm">
          <li
            v-for="(item, index) in listData"
            :key="index"
          >
            <span class="cate">{{item.type}}</span>
            <dl>
              <dd v-for="l in item.list" :key="l.id" :class="{active: typeId == l.id}">
                <span
                  class="right-arrow"
                  style="font-size:15px;"
                  :class="{on: listId == l.id }"
                  v-if="!l.list.length"
                  @click="changeList(l.id)"
                >{{l.title}}</span>
                <span
                  class="right-arrow"
                  style="font-size:15px;"
                  v-if="l.list.length"
                  @click="targetList($event)"
                >{{l.title}}</span>
                <a
                  :class="{on: listId == three.id }"
                  @click="changeList(three.id)"
                  v-if="l.list.length"
                  v-for="(three, index3) in l.list"
                  :key="index3"
                >{{three.title}}</a>
              </dd>
            </dl>
          </li>
        </ul>
      </div>
      <div class="right" ref="right">
        <div class="right-title">
          量表简介及需知
          <!-- <span class="form-share">分享量表</span> -->
        </div>
        <h2 class="list-title">{{listInfo.title}}</h2>
        <div class="list-info" v-html="listInfo.desc"></div>
        <div class="list-video" v-if="listInfo.video_url">
          <h3>视频教程：</h3>
          <div class="video-li" v-for="(item, index) in listInfo.video_url" :key="index">
            <a :href="item" target="_blank">视频教程{{index + 1}}</a>
          </div>
        </div>
        <template v-if="status === 0">
          <div class="form-tips" style="margin: 100px 0;">
            <span>请在左边选择要查看的量表</span>
          </div>
        </template>
        <template v-if="status === 1">
          <div class="btn-box a-ta-c">
            <button class="btn btn-o" @click="startTest(listInfo.id)">开始测评</button>
            <button class="btn" @click="buyTest(listInfo.id)">购买量表</button>
          </div>
        </template>
        <template v-if="status === 2">
          <h2 class="list-title">暂无使用资质，请先申请资质</h2>
          <div class="btn-box a-ta-c">
            <button class="btn" @click="applyTest(listInfo.id)">使用资质申请</button>
          </div>
        </template>
        <template v-if="status === 3">
          <h2 class="list-title">量表使用资质审核中，请耐心等待！</h2>
        </template>
        <template v-if="status === 4">
          <h2 class="list-title">量表使用额度已用尽，请购买！</h2>
          <div class="btn-box a-ta-c">
            <button class="btn" @click="buyTest(listInfo.id)">购买量表</button>
          </div>
        </template>
        <!-- <div class="form-tips"><span>注：购买后跳出年度培训计划或培训视频</span></div> -->
      </div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
    <!-- my confirm -->
    <my-confirm ref="myComfirm"></my-confirm>
    <!-- pay type -->
    <div class="pay-box" v-show="payBox">
      <div class="title">购买量表</div>
      <div class="pay-content">
        <div class="pay-tit">购买数量:</div>
        <div class="pay-input">
          <input type="text" v-model="num" placeholder="请输入购买数量" @input="handelInput" />
        </div>
        <div class="pay-tit">支付方式:</div>
        <div class="pay-radio">
          <input type="radio" name="payway" value="wx_pc" id="pay1" v-model="payType">
          <label for="pay1">
            <div class="img">
              <img src="../assets/icon/icon_weixinpay.png">
            </div>
            <div class="name">微信支付</div>
          </label>
          <input type="radio" name="payway" value="ali_pc" id="pay2" v-model="payType">
          <label for="pay2">
            <div class="img">
              <img src="../assets/icon/icon_alipay.png">
            </div>
            <div class="name">支付宝支付</div>
          </label>
        </div>
        <div class="pay-tit">支付金额:<span class="pay-price">{{price}}</span></div>
        
      </div>
      <div class="btn-box">
        <button class="btn btn-o" @click="payBox = false">取消</button>
        <button class="btn" :class="{disabled: !is_submit}" @click="payOnline">确定</button>
      </div>
    </div>
    <div class="ali_pay" v-show="alipays"></div>
    <qr-code v-if="wei_url" :url="wei_url"></qr-code>
    <div class="bg-blank" v-show="payBox || wei_url" @click="closePay"></div>
  </div>
</template>

<script>
    import HeaderNav from '@/components/HeaderNav'
    import FooterNav from '@/components/FooterNav'
    import MyConfirm from '@/components/MyConfirm'
    import QrCode from '@/components/QRCode'

    export default {
        name: 'user',
        data() {
            return {
                current: 'evaluation',
                listData: [],
                titleId: '',
                payType: 'wx_pc',
                alipays: false,
                alipayPage: '',
                payBox: false,
                wei_url: '',
                typeId: 0,
                listId: 0,
                threeId: 0,
                listInfo: {},
                buyId: '',
                status: 0, // status: 1-正常  2-无资质，申请资质  3-资质审核中  4-量表次数使用尽，需购买
                num: '', // 购买数量
                price: '0.00', // 支付金额
                is_submit: false,
            }
        },
        methods: {
            getList: function () {
                this.$post('question/titleList').then((res) => {
                    // console.log(1111, res)
                    if (res.code === 1) {
                        this.listData = res.data
                        this.getListInfo()
                    }
                }).catch((err) => {
                    this.$layer.msg('网络异常，请稍后重试12！')
                })
            },
            getListInfo: function () {
                let params = {
                    title_id: this.listId
                }
                this.$post('exam/titleInfo', params).then((res) => {
                    if (res.code === 1) {
                        this.status = 1
                        this.listInfo = res.data
                    } else if (res.code === -10) {
                        this.status = 2
                        this.listInfo = res.data
                    } else if (res.code === -20) {
                        this.status = 3
                        this.listInfo = res.data
                    } else if (res.code === -30) {
                        this.status = 4
                        this.listInfo = res.data
                    }
                }).catch((err) => {
                    this.$layer.msg('网络异常，请稍后重试23！')
                })
            },
            targetList(e) {
                let obj = e.target.parentNode
                console.log(obj)
                if (this.hasClass(obj, 'active')) {
                    obj.classList.remove('active')
                } else {
                    obj.classList.add('active')
                }
            },
            hasClass(e, cls) {
                return (' ' + e.className + ' ').indexOf(' ' + cls + ' ') > -1;
            },
            changeList: function (id) {
                this.listId = id
                this.getListInfo()
            },
            startTest: function (id) {
                this.$router.push('/baseinfo?id=' + id)
            },
            buyTest: function (id) {
                this.buyId = id
                this.payBox = true
            },
            // 在线支付
            payOnline: function () {
              if (!this.is_submit) {
                return
              }
                let params = {
                    title_id: this.listId,
                    pay_type: this.payType,
                    num: this.num,
                }
                // this.$layer.msg('功能正在开发中，敬请期待！')
                // return false
                this.$post('question/buyQuestion', params).then(res => {
                    if (this.payType == 'ali_pc') {
                        this.payBox = false;
                        this.alipays = true;
                        const div = document.createElement('div');
                        div.innerHTML = res;
                        document.body.appendChild(div);
                        document.forms.alipaysubmit.submit();
                    }
                    if (res.code === 1) {
                        this.payBox = false
                        this.wei_url = res.data.data;
                    } else {
                        this.$layer.msg(res.msg)
                    }
                })
            },
            // 防抖
            handelInput () {
              this.timer && clearTimeout(this.timer)
              this.timer = setTimeout(()=>{
                this.getPrice()
              },1000)
            },
            // 获取价格
            getPrice () {
              if (this.num == '') {
                this.is_submit = false
                this.$layer.msg('请输入购买数量！')
                return
              }
              if (!this.isPositiveInteger(this.num)) {
                this.is_submit = false
                this.$layer.msg('您的输入有误，请输入正整数！')
                return
              }
              let params = {
                    title_id: this.listId,
                    num: this.num,
                }
                this.$post('question/price', params).then(res => {
                    if (res.code === 1) {
                      this.is_submit = true
                      this.price = res.data.price || ''
                    } else {
                      this.$layer.msg(res.msg)
                    }
                })
            },
            isPositiveInteger(s){//是否为正整数
              var re = /^[0-9]+$/ ;
              return re.test(s)
            },
            applyTest: function (id) {
                let confirm = this.$refs.myComfirm
                confirm.open('提示', {
                    content: '申请使用量表?',
                    btn: ['取消', '确定']
                }, () => {
                    confirm.hide()
                }, () => {
                    confirm.hide()
                    console.log(id)
                    this.$router.push({path: '/apply', query: {id: this.listId}})
                    // let params = {
                    //   title_id: id
                    // }
                    // this.$post('question/qualification', params).then(res => {
                    //   this.$layer.msg(res.msg)
                    //   if (res.code === 1) {
                    //   }
                    // }).catch(response => {
                    //   this.$layer.msg('网络异常，请稍后重试45！')
                    // })
                })
            },
            // 关闭支付
            closePay() {
                this.alipays = false
                this.wei_url = ''
                this.payBox = false
            },
            applyTest1() {
                this.$layer.msg('功能正在开发中，敬请期待！')
            }
        },
        components: {
            HeaderNav,
            FooterNav,
            MyConfirm,
            "qr-code": QrCode,
        },
        mounted: function () {
            var h = document.documentElement.clientHeight || document.body.clientHeight
            this.$refs.left.style.minHeight = h - 144 + 'px'
            this.$refs.right.style.minHeight = h - 144 + 'px'
        },
        created() {
            if (this.$route.query.id) {
                this.listId = this.$route.query.id
            }
            if (this.$route.query.type_id) {
                this.typeId = this.$route.query.type_id
            }
            this.getList()
        }
    }
</script>

<style lang="less" scoped>
  @import url("../assets/css/page/testcenter");
</style>
