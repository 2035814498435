<template>
    <div id="qrCode">
      <div id='code'></div>
      <canvas id="canvas"></canvas>
      <p>请用微信扫描二维码</p>
    </div>
</template>
<script>
  import QRCode from 'qrcode'
    export default{
        name:'codes',
        props:{
            url:String
        },
        data(){
            return {
              msg: 'hello vue',
              codes:''

            }
        },

      components: {
        QRCode: QRCode
      },

      methods: {
        useqrcode(){
          var canvas = document.getElementById('canvas')
          QRCode.toCanvas(canvas, this.url, function (error) {
            if (error) console.error(error)
            console.log('QRCode success!');
          })
        }
      },

      mounted(){
            console.log(this.url)
            this.useqrcode();
      }
    }
</script>
<style lang="less">
    #qrCode{
            width: 164px;
            background-color: #fff;
            border-radius: 4px;
            overflow: hidden;
            position: fixed;
            top: 50%;
            left: 50%;
            z-index: 100;
            transform: translate(-50%, -50%);
            text-align: center;
            p{
                text-align: center;
                padding-bottom: 10px;
            }
    }
</style>
